export const environment = {

  production: false,
  localDev: false,

  // used for websocket server -> client communication
  "socketio_server_url": 'https://test.zimmerer-treff.com',

  backendUrl: 'https://test.zimmerer-treff.com',
  publicFolder: '/build/', // is used for code compatibility with web project

  SentryDSN: "https://2e8924b746274473aad666526c9443a1@glitchtip.aceart.de/3",
}
